<!-- src/components/components-fit/società/affiliazioni/TutelaMinoriAffiliazione.vue -->
<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div class="mx-auto my-auto text-center py-5 my-5" v-if="loadingView">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <div v-if="!loadingView && !errore">
    <span class="pb-3">
      Responsabile della Tutela dei Minori (Art. 18 Regolamento FITP contro la
      violenza di genere)</span
    >
    <div class="row pb-3 align-items-top pt-2">
      <div class="col-4">
        <TextInput
          name="tutore_minori_nome"
          type="text"
          :value="tutore_minori_nome"
          @InputEvent="tutore_minori_nome = $event"
          :placeholder="'Nome'"
          style="margin: 0px !important"
        />
        <div
          class="text-danger pb-3"
          v-if="!tutore_minori_nome && showMsgErrore"
        >
          Obbligatorio
        </div>
      </div>
      <div class="col-4">
        <TextInput
          name="tutore_minori_cognome"
          type="text"
          :value="tutore_minori_cognome"
          @InputEvent="tutore_minori_cognome = $event"
          :placeholder="'Cognome'"
          style="margin: 0px !important"
        />
        <div
          class="text-danger pb-3"
          v-if="!tutore_minori_cognome && showMsgErrore"
        >
          Obbligatorio
        </div>
      </div>
      <div class="col-4">
        <TextInput
          name="tutore_minori_cod_fiscale"
          type="text"
          :value="tutore_minori_cod_fiscale"
          @InputEvent="tutore_minori_cod_fiscale = $event"
          :placeholder="'Codice fiscale'"
          style="margin: 0px !important"
        />
        <div
          class="text-danger pb-3"
          v-if="!tutore_minori_cod_fiscale && showMsgErrore"
        >
          Obbligatorio
        </div>
      </div>
    </div>
    <div class="row pb-3 align-items-top pt-2">
      <span class="pb-3"> Comune di nascita</span>
      <div class="row">
        <div class="row">
          <div class="col-md-2 mb-4">
            <label class="fw-bold text-gray-600" for="italia">Italia</label>

            <input
              v-model="straniero"
              class="form-check-input mt-8"
              type="radio"
              name="straniero"
              id="italia"
              :value="false"
              :checked="!straniero"
              @click="
                tutore_minori_comune_nascita_label = '';
                tutore_minori_comune_nascita = 0;
                straniero = false;
                tutore_minori_stato_nascita = 0;
              "
            />
          </div>
          <div class="col-md-6 mb-4">
            <label class="fw-bold text-gray-600">Comune di nascita</label>
            <input
              name="tutore_minori_comune_nascita_label"
              type="text"
              placeholder="Comune di nascita - Digita almeno 3 caratteri..."
              class="form-control"
              v-model="tutore_minori_comune_nascita_label"
              @input="
                spliceComuniNascita();
                setComuniNascita($event.target.value);
              "
              @focus="inputFocus = 'comuneN'"
              @keyup.delete="
                spliceComuniNascita();
                tutore_minori_comune_nascita = 0;
              "
              :disabled="straniero"
            />
            <input
              name="tutore_minori_comune_nascita"
              type="hidden"
              class="form-control"
              v-model="tutore_minori_comune_nascita"
              autocomplete="off"
            />
            <ListComuni
              v-if="comuniNascita && inputFocus === 'comuneN'"
              :list="comuniNascita"
              @hideList="spliceComuniNascita"
              @selectedElement="
                tutore_minori_comune_nascita_label = $event.name;
                tutore_minori_comune_nascita = $event.value;
                spliceComuniNascita();
              "
            ></ListComuni>
            <div
              class="text-danger pb-3"
              v-if="!tutore_minori_comune_nascita && showMsgErrore"
            >
              Obbligatorio
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 mb-4">
            <label class="fw-bold text-gray-600" for="estero">Estero</label>
            <input
              v-model="straniero"
              class="form-check-input mt-8"
              type="radio"
              name="straniero"
              id="estero"
              :value="true"
              :checked="straniero"
              @click="
                tutore_minori_comune_nascita_label = '';
                tutore_minori_comune_nascita = 0;
                straniero = false;
                tutore_minori_stato_nascita = 0;
              "
            />
          </div>
          <div class="col-md-6 mb-4">
            <label class="fw-bold text-gray-600">Nazione nascita</label>

            <SelectInput
              :options="statiNascita"
              name="tutore_minori_stato_nascita"
              :disabled="!straniero"
              :reset="!straniero"
              :value="tutore_minori_stato_nascita"
              @changeSelect="tutore_minori_stato_nascita = $event"
            />
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6">
        <input
          name="tutore_minori_comune_nascita_label"
          type="text"
          placeholder="Comune di nascita - Digita almeno 3 caratteri..."
          class="form-control"
          v-model="tutore_minori_comune_nascita_label"
          @input="
            spliceComuniNascita();
            setComuniNascita($event.target.value);
          "
          @focus="inputFocus = 'comuneN'"
          @keyup.delete="
            spliceComuniNascita();
            tutore_minori_comune_nascita = 0;
          "
        />
        <input
          name="tutore_minori_comune_nascita"
          type="hidden"
          class="form-control"
          v-model="tutore_minori_comune_nascita"
          autocomplete="off"
        />
        <ListComuni
          v-if="comuniNascita && inputFocus === 'comuneN'"
          :list="comuniNascita"
          @hideList="spliceComuniNascita"
          @selectedElement="
            tutore_minori_comune_nascita_label = $event.name;
            tutore_minori_comune_nascita = $event.value;
            spliceComuniNascita();
          "
        ></ListComuni>
        <div
          class="text-danger pb-3"
          v-if="!tutore_minori_comune_nascita && showMsgErrore"
        >
          Obbligatorio
        </div>
      </div> -->
    </div>
    <span class="pb-3"> Reperibile ai seguenti recapiti:</span>
    <div class="row pb-3 align-items-top pt-2">
      <div class="col-4">
        <TextInput
          name="tutore_minori_telefono"
          type="text"
          :value="tutore_minori_telefono"
          @InputEvent="tutore_minori_telefono = $event"
          :placeholder="'Telefono'"
          style="margin: 0px !important"
        />
        <div
          class="text-danger pb-3"
          v-if="!tutore_minori_telefono && showMsgErrore"
        >
          Obbligatorio
        </div>
      </div>
      <div class="col-4">
        <TextInput
          name="tutore_minori_mail"
          type="text"
          :value="tutore_minori_mail"
          @InputEvent="tutore_minori_mail = $event"
          :placeholder="'Email'"
          style="margin: 0px !important"
        />
        <div
          class="text-danger pb-3"
          v-if="!tutore_minori_mail && showMsgErrore"
        >
          Obbligatorio
        </div>
      </div>
    </div>
  </div>
  <div class="text-danger text-center ps-4" v-if="!loadingView && errore">
    {{ errore }}
  </div>
  <div class="text-end">
    <button
      class="badge bg-secondary rounded blueFit fs-5"
      @click="addTutelaMinore()"
    >
      Salva Modifiche
    </button>
  </div>
</template>

<script>
import { defineComponent, ref, computed, getCurrentInstance } from "vue";
import TextInput from "@/components/components-fit/utility/inputs/TextInput.vue";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import {
  addTutelaMinoreAffiliazione,
  viewTutelaMinoriAffiliazione,
} from "@/requests/checkAffiliazione";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { useStore } from "vuex";
import ListComuni from "@/components/components-fit/utility/ListComuni.vue";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
export default defineComponent({
  name: "tutela-societa",
  props: ["societa"],
  components: {
    TextInput,
    Loading,
    ListComuni,
    SelectInput,
  },
  setup(props) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const tutore_minori_nome = ref(null);
    const tutore_minori_cognome = ref(null);
    const tutore_minori_cod_fiscale = ref(null);
    const tutore_minori_telefono = ref(null);
    const tutore_minori_mail = ref(null);
    const tutore_minori_comune_nascita_label = ref("");
    const tutore_minori_comune_nascita = ref(null);
    const tutore_minori_stato_nascita = ref(null);
    const showMsgErrore = ref(false);
    const isLoading = ref(false);

    const loadingView = ref(false);
    const errore = ref(null);

    const getTutelaMinore = async () => {
      loadingView.value = true;
      await viewTutelaMinoriAffiliazione(props.societa).then((res) => {
        if (res.status == 200) {
          errore.value = null;
          tutore_minori_nome.value = res.data.results[0].tutore_minori_nome;
          tutore_minori_cognome.value =
            res.data.results[0].tutore_minori_cognome;
          tutore_minori_cod_fiscale.value =
            res.data.results[0].tutore_minori_cod_fiscale;
          tutore_minori_telefono.value =
            res.data.results[0].tutore_minori_telefono;
          tutore_minori_mail.value = res.data.results[0].tutore_minori_mail;
          tutore_minori_comune_nascita_label.value =
            res.data.results[0].comune_nascita;
          tutore_minori_comune_nascita.value =
            res.data.results[0].tutore_minori_comune_nascita;
          tutore_minori_stato_nascita.value =
            res.data.results[0].tutore_minori_stato_nascita;

          if (tutore_minori_stato_nascita.value) straniero.value = true;
          else straniero.value = false;
        } else {
          errore.value = res.data.message
            ? res.data.message
            : "Attenzione! Si è verificato un errore. Riprovare più tardi";
        }
        loadingView.value = false;
      });
    };
    getTutelaMinore();

    const addTutelaMinore = async () => {
      if (
        (!tutore_minori_comune_nascita.value ||
          !tutore_minori_stato_nascita.value) &&
        (!tutore_minori_nome.value ||
          !tutore_minori_cognome.value ||
          !tutore_minori_cod_fiscale.value ||
          !tutore_minori_telefono.value ||
          !tutore_minori_mail.value)
      ) {
        alertFailed("Compilare i campi obbligatori");
        showMsgErrore.value = true;
      } else {
        isLoading.value = true;
        await addTutelaMinoreAffiliazione(
          props.societa,
          tutore_minori_nome.value,
          tutore_minori_cognome.value,
          tutore_minori_cod_fiscale.value,
          tutore_minori_telefono.value,
          tutore_minori_mail.value,
          tutore_minori_comune_nascita.value,
          tutore_minori_stato_nascita.value
        ).then((res) => {
          if (res.status == 200) {
            alertSuccess("Modifiche salvate correttamente");
            showMsgErrore.value = false;
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          }
          isLoading.value = false;
        });
      }
    };

    const spliceComuniNascita = () => {
      store.commit("spliceArrayFromName", "comuni_nascita");
    };
    const setComuniNascita = (string) => {
      if (string.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            nomec: string,
          },
          apiLink: globalApi.COMUNI_NASCITA_LIST,
        });
      }
    };
    const inputFocus = ref("");

    const straniero = ref(false);

    const statiNascita = computed(() =>
      store.getters.getStateFromName("stati_nascita")
    );

    const keys = ref("sn");

    if (!statiNascita.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    return {
      statiNascita,
      straniero,
      inputFocus,
      spliceComuniNascita,
      setComuniNascita,
      comuniNascita: computed(() =>
        store.getters.getStateFromName("comuni_nascita")
      ),

      tutore_minori_comune_nascita_label,
      tutore_minori_comune_nascita,
      trueFalseLookup: [
        { id: true, label: "Si" },
        { id: false, label: "No" },
      ],
      tutore_minori_nome,
      tutore_minori_cognome,
      tutore_minori_cod_fiscale,
      tutore_minori_telefono,
      tutore_minori_mail,
      addTutelaMinore,
      showMsgErrore,
      isLoading,
      errore,
      loadingView,
      tutore_minori_stato_nascita,
    };
  },
});
</script>

<style></style>
