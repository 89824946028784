<template>
  <!--begin::details View-->
  <Form
    @submit="onSubmit"
    :validation-schema="schema"
    @invalid-submit="onInvalidSubmit"
  >
    <!-- 1,2,9,10,11 -->
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>

    <div
      v-else-if="loaded && status != 200"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <template v-else>
      <div
        class="card shadow mb-5 mb-xl-10"
        v-for="attoS in atto"
        :key="attoS.id"
      >
        <div class="bg-light rounded-1 m-5 p-5">
          <div class="row">
            <div class="col-md-4 mb-4">
              <label class="fw-bold text-gray-600">Tipo*</label>
              <SelectInput
                :options="tipo"
                name="forma_id"
                placeholder="Seleziona"
                :value="attoS.forma_id"
                @changeSelect="attoS.forma_id = $event"
              />
            </div>
            <div
              class="col-md-3 mb-4"
              v-if="societa.id_tipo === 1 && attoS.forma_id != 3"
            >
              <!-- scrittura privata autenticata ha i campi: data reg, ufficio reg/ente, estremi reg
             scrittura privata autenticata non ha campi
             scrittura privata registrata ha i campi come il primo-->
              <label class="fw-bold text-gray-600">Data registrazione</label>
              <Datepicker
                v-model="attoS.data_atto"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                :startDate="new Date()"
                inputClassName="h-28"
                locale="it"
                autoApply
                @update:model-value="attoS.data_atto = fromUTCtoISODate($event)"
              />
              <Field type="hidden" name="data_atto" v-model="attoS.data_atto" />
              <ErrorMessage
                class="help-message"
                style="color: red"
                name="data_atto"
              />
            </div>
          </div>
          <div class="row" v-if="societa.id_tipo === 1 && attoS.forma_id != 3">
            <div class="col-md-5 mb-2">
              <label class="fw-bold text-gray-600"
                >Ufficio reg. / Ente autenticante*</label
              >
              <TextInput
                name="ufficio_registro"
                type="text"
                :value="attoS.ufficio_registro"
              />
            </div>
            <div class="col-md-5 mb-2">
              <label class="fw-bold text-gray-600"
                >Estremi registrazione*</label
              >
              <TextInput
                name="estremi_registrazione"
                type="text"
                :value="attoS.estremi_registrazione"
              />
            </div>
          </div>
        </div>
        <template v-if="societa.id_tipo != 1">
          <!-- in tutti gli altri casi, la lookup restituira sempre il valore atto pubblico, ed avra sempre i campi
        cognome notaio, nome notaio, distretto, n ordine e rep notarile -->
          <div class="bg-light rounded-1 m-5 p-5">
            <div class="row">
              <div class="col-md-3 mb-2">
                <label class="fw-bold text-gray-600">Cognome notaio*</label>
                <TextInput
                  name="cognome_notaio"
                  type="text"
                  :value="attoS.cognome_notaio"
                />
              </div>
              <div class="col-md-3 mb-2">
                <label class="fw-bold text-gray-600">Nome notaio*</label>
                <TextInput
                  name="nome_notaio"
                  type="text"
                  :value="attoS.nome_notaio"
                />
              </div>
              <div class="col-md-3 mb-2">
                <label class="fw-bold text-gray-600">Distretto*</label>
                <SelectInput
                  :options="distretti"
                  :value="attoS.distretto_notaio"
                  name="distretto_notaio"
                  @changeSelect="attoS.distretto_notaio = $event"
                />
              </div>
              <div class="col-md-3 mb-2">
                <label class="fw-bold text-gray-600"
                  >N° ordine e Rep. notarile*</label
                >
                <TextInput
                  name="n_ordine"
                  type="text"
                  :value="attoS.n_ordine"
                />
              </div>
            </div>
          </div>
          <div class="bg-light rounded-1 m-5 p-5">
            <div class="row">
              <div class="col-md-4 mb-2">
                <label class="fw-bold text-gray-600"
                  >N° Reg. Persone Giuridiche*</label
                >
                <TextInput
                  name="n_registro_pers_giuridiche"
                  type="text"
                  :value="attoS.n_registro_pers_giuridiche"
                />
              </div>
              <div class="col-md-4 mb-2">
                <label class="fw-bold text-gray-600">Prefettura*</label>
                <SelectInput
                  :options="prefettura"
                  :value="attoS.prefettura"
                  name="prefettura"
                  @changeSelect="
                    attoS.prefettura = $event;
                    attoPrefettura = $event;
                  "
                />
              </div>
              <div class="col-md-4 mb-2">
                <label class="fw-bold text-gray-600">Regione*</label>
                <SelectInput
                  :options="regioni"
                  :value="attoS.regione"
                  name="regione"
                  @changeSelect="
                    attoS.regione = $event;
                    attoRegione = $event;
                  "
                />
              </div>
            </div>
            <div class="row" v-if="societa.id_tipo != 2">
              <div class="col-md-4 mb-4">
                <!--  -->
                <label class="fw-bold text-gray-600">N° Reg. Imprese</label>
                <TextInput
                  name="n_registro_imprese"
                  type="text"
                  :value="attoS.n_registro_imprese"
                />
              </div>
              <div class="col-md-4 mb-4">
                <label class="fw-bold text-gray-600">Camera di commercio</label>
                <SelectInput
                  :options="prefettura"
                  :value="attoS.camera_commercio"
                  name="camera_commercio"
                  @changeSelect="attoS.camera_commercio = $event"
                />
              </div>
            </div>
          </div>
        </template>

        <div class="bg-light rounded-1 m-5 p-5 mt-0">
          <div class="row">
            <label class="fw-bold text-gray-600"
              >Possesso requisiti statuto</label
            >
            <p class="fs-7 text-gray-600">
              Completare la dichiarazione di possesso di requisiti dello statuto
              secondo quanto previsto da ex art. 90 legge 289/2002 ed ex
              delibera del Consiglio Nazionale CONI del 15/07/2004 N.1273
            </p>
          </div>
          <div class="row font-italic text-gray-600 fst-italic">
            <span class="d-flex pb-3 align-items-center">
              a far data da &nbsp;
              <Datepicker
                v-model="attoS.fd1"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                :startDate="new Date()"
                inputClassName="h-28"
                locale="it"
                autoApply
                @update:model-value="attoS.fd1 = fromUTCtoISODate($event)"
              />
              <Field type="hidden" name="fd1" v-model="attoS.fd1" />
              <ErrorMessage
                class="help-message"
                style="color: red"
                name="fd1"
              />
              &nbsp; nello statuto sono espressamente previsti, oltre alla sede
              legale:
            </span>
            <span class="d-flex pb-3 align-items-center">
              a) La denominazione; (art. &nbsp;
              <TextInput
                name="fd2"
                type="text"
                :value="attoS.fd2"
                style="width: 6rem"
              />
              &nbsp; )
            </span>
            <span class="d-flex pb-3 align-items-center">
              b) L’oggetto sociale, con specifico riferimento all’esercizio in
              via stabile e principale dell’organizzazione e gestione di
              attività sportive dilettantistiche, ivi comprese la formazione, la
              didattica, la preparazione e l'assistenza all’attività sportiva
              dilettantistica e alla possibilità di svolgere eventuali attività
              secondarie o strumentali rispetto a quella sportiva nei limiti
              stabiliti ai sensi dell’art. 9 D. Lgs 36/2021 (art. &nbsp;
              <TextInput
                name="fd3"
                type="text"
                :value="attoS.fd3"
                style="width: 6rem"
              />
              &nbsp; );
            </span>
            <span class="d-flex pb-3 align-items-center"
              >c) L'attribuzione della rappresentanza legale dell'associazione
              (art. &nbsp;
              <TextInput
                name="fd4"
                type="text"
                :value="attoS.fd4"
                style="width: 6rem"
              />
              &nbsp; );
            </span>
            <span class="d-flex pb-3 align-items-center">
              d) Il divieto per gli amministratori e i componenti dei consigli
              direttivi di ricoprire qualsiasi carica in altre società o
              associazioni sportive dilettantistiche nell’ambito della medesima
              Federazione Sportiva Nazionale, disciplina sportiva associata o
              Ente di Promozione Sportiva riconosciuti dal CONI e, ove
              paralimpici, riconosciuti dal CIP;
            </span>
            <span class="d-flex pb-3 align-items-center">
              e) L’obbligo di tesserare tutti i soci;
            </span>
            <span class="d-flex pb-3 align-items-center"
              >f) L’assenza di fini di lucro ai sensi dell’articolo 8 del D.
              Lgs. n. 36/2021 con obbligo di destinare eventuali utili di
              gestione allo svolgimento dell’attività sportiva prevista dallo
              statuto o all’incremento del proprio patrimonio (art. &nbsp;
              <TextInput
                name="fd5"
                type="text"
                :value="attoS.fd5"
                style="width: 6rem"
              />
              &nbsp; );
            </span>
            <span class="d-flex pb-3 align-items-center"
              >g) Le norme sull’ordinamento interno ispirato a principi di
              democrazia e di uguaglianza dei diritti i tutti gli associati, con
              la previsione dell’elettività delle cariche sociali, fatte salve
              le società sportive dilettantistiche che assumono la forma di
              società di capitali o cooperative per le quali si applicano le
              disposizioni del Codice (art. &nbsp;
              <TextInput
                name="fd6"
                type="text"
                :value="attoS.fd6"
                style="width: 6rem"
              />
              &nbsp; );
            </span>
            <span class="d-flex pb-3 align-items-center"
              >h) L'obbligo di redazione di rendiconti economico-finanziari,
              nonché le modalità di approvazione degli stessi da parte degli
              organi statutari (art. &nbsp;
              <TextInput
                name="fd7"
                type="text"
                :value="attoS.fd7"
                style="width: 6rem"
              />
              &nbsp; );
            </span>
            <span class="d-flex pb-3 align-items-center"
              >i) l’impegno all’adempimento degli obblighi di carattere
              economico, secondo le norme e le deliberazioni federali, nei
              confronti della FITP e degli altri affiliati e del pagamento di
              quanto ancora dovuto alla FITP ed agli altri affiliati anche in
              ogni caso di cessazione di appartenenza alla FITP;
            </span>
            <span class="d-flex pb-3 align-items-center"
              >l) l’obbligo per i componenti del Consiglio direttivo (o di
              amministrazione) in carica al momento della cessazione di
              appartenenza alla FITP di assolvere personalmente e solidalmente
              al pagamento di quanto ancora dovuto alla FITP ed agli altri
              affiliati;
            </span>
            <span class="d-flex pb-3 align-items-center">
              m) l’obbligo di osservare e far osservare ai propri soci il
              vincolo di giustizia e la clausola compromissoria;
            </span>
            <span class="d-flex pb-3 align-items-center"
              >n) Le modalità di scioglimento dell'associazione (art. &nbsp;
              <TextInput
                name="fd8"
                type="text"
                :value="attoS.fd8"
                style="width: 6rem"
              />
              &nbsp; ); </span
            ><span class="d-flex pb-3 align-items-center"
              >o) L'obbligo di devoluzione ai fini sportivi del patrimonio in
              caso di scioglimento delle società e delle associazioni (art.
              &nbsp;
              <TextInput
                name="fd9"
                type="text"
                :value="attoS.fd9"
                style="width: 6rem"
              />
              &nbsp; );
            </span>
            <span class="d-flex pb-3 align-items-center"
              >ché a far data dal &nbsp;
              <Datepicker
                v-model="attoS.fd10"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                :startDate="new Date()"
                inputClassName="h-28"
                locale="it"
                @update:model-value="attoS.fd10 = fromUTCtoISODate($event)"
                autoApply
                :teleport-center="fromModal ? true : false"
                :auto-position="fromModal ? false : true"
              />
              <Field type="hidden" name="fd10" v-model="attoS.fd10" />
              <ErrorMessage
                class="help-message"
                style="color: red"
                name="fd10"
              />
              &nbsp;
            </span>
            <span class="d-flex pb-3 align-items-center"
              >nello statuto è espressamente previsto, in osservanza alla
              Delibera del Consiglio Nazionale CONI del 15/07/2004 N.1273,
              l’obbligo di conformarsi alle norme ed alle direttive del CONI
              nonché allo Statuto e ai regolamenti della Federazione Italiana
              Tennis e Padel, nonché l’obbligo di osservare e di far osservare
              ai propri soci lo statuto e i regolamenti della FITP, le
              deliberazioni e le decisioni dei suoi organi nonché la normativa
              del CONI.
            </span>
          </div>
          <div class="row text-end" v-if="attoS.data_modifica">
            <label class="fw-bold text-gray-600"
              >ultima modifica: {{ attoS.data_modifica }}</label
            >
          </div>
        </div>
      </div>
      <div class="p-5" v-for="attoS in atto" :key="attoS.id">
        <input
          class="form-check-input me-2"
          type="checkbox"
          @click="attoS.conferma_atto = !attoS.conferma_atto"
          :checked="attoS.conferma_atto"
        />Dichiaro che i dati forniti all'interno dell'atto sono veritieri*
        {{ atto.conferma_atto }}
        <Field
          type="hidden"
          name="conferma_atto"
          v-model="attoS.conferma_atto"
        />
        <ErrorMessage
          class="help-message"
          style="color: red"
          name="conferma_atto"
        />
      </div>

      <!--end::details View-->
      <div v-if="!readOnly" class="text-end">
        <button class="badge bg-secondary rounded blueFit fs-5">
          Salva Modifiche
        </button>
        <!--   <span type="button" class="badge bg-light-red rounded blueFit">
      Annulla Modifiche
    </span> -->
      </div>
    </template>
  </Form>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  getCurrentInstance,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import TextInput from "../../../components/components-fit/utility/inputs/TextInput.vue";
import SelectInput from "../../../components/components-fit/utility/inputs/SelectInput.vue";
import "vue3-date-time-picker/dist/main.css";
import Datepicker from "vue3-date-time-picker";
import isEnabled from "@/composables/isEnabled.js";
import { useRouter } from "vue-router";

import { viewAtto, editAtto } from "@/requests/societaAtto";
import { alertSuccess, alertFailed } from "@/composables/swAlert";
import {
  useFormatDateDMY,
  format,
  fromUTCtoISODate,
} from "@/composables/formatDate";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import { useStore } from "vuex";

export default defineComponent({
  name: "atto-societa",
  props: ["societa", "fromModal"],
  components: {
    TextInput,
    SelectInput,
    Datepicker,
    Form,
    Field,
    ErrorMessage,
  },
  setup(props) {
    const store = useStore();
    const atto = ref([]);

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const status = ref(0);
    const loaded = ref(false);

    const tipo = computed(() =>
      store.getters.getStateFromName("societa_tipo_forma")
    );
    store.commit("spliceArrayFromName", "societa_tipo_forma");
    store.dispatch("setStoreData", {
      keys: { id_societa_tipo: props.societa.id_tipo },
      apiLink: globalApi.LOOKUP_ATTO_TIPO_GET,
    });

    const prefettura = computed(() =>
      store.getters.getStateFromName("prefettura")
    );
    const regioni = computed(() =>
      store.getters.getStateFromName("istat_regioni_province")
    );
    const distretti = computed(() =>
      store.getters.getStateFromName("societa_atto_distretti_notai")
    );
    const keys = ref("pre|irp|sadn");
    // verifico se i filtri sono vuoti, se si li prendo da api, passando le keys qui sopra e il link all'api
    if (!prefettura.value || !regioni.value || !distretti.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const attoRegione = ref(null);
    const attoPrefettura = ref(null);

    const getAtto = () => {
      loaded.value = false;
      viewAtto(props.societa.id).then((res) => {
        if (!res.results.length) {
          atto.value = [
            {
              forma_id: null,
              data_atto: "",
              ufficio_registro: "",
              estremi_registrazione: "",
              prefettura: null,
              regione: null,
              fd1: "",
              fd2: "",
              fd3: "",
              fd4: "",
              fd5: "",
              fd6: "",
              fd7: "",
              fd8: "",
              fd9: "",
              fd10: "",
              id: props.societa.id,
              id_societa: props.societa.id,
              conferma_atto: null,
            },
          ];
        } else {
          atto.value = res.results;
        }
        if (props.societa.id_tipo != 1) {
          atto.value[0].forma_id = 1;
        } else {
          if (atto.value[0].forma_id == 1) {
            atto.value[0].forma_id = null;
          }
        }
        attoRegione.value = atto.value[0].regione;
        attoPrefettura.value = atto.value[0].prefettura;
        status.value = res.status;
        loaded.value = true;
      });
    };
    getAtto();

    const socIdTipo = ref(props.societa.id_tipo);

    const schema = yup.object().shape({
      forma_id: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      ufficio_registro: yup.string().when("forma_id", {
        is: (forma_id) => parseInt(socIdTipo.value) == 1 && forma_id != 3,
        then: yup.string().required("Obbligatorio"),
      }),
      estremi_registrazione: yup.string().when("forma_id", {
        is: (forma_id) => parseInt(socIdTipo.value) == 1 && forma_id != 3,
        then: yup.string().required("Obbligatorio"),
      }),
      cognome_notaio: yup.string().when("forma_id", {
        is: () => parseInt(socIdTipo.value) >= 2,
        then: yup.string().required("Obbligatorio"),
        otherwise: yup.string(),
      }),
      nome_notaio: yup.string().when("forma_id", {
        is: () => parseInt(socIdTipo.value) >= 2,
        then: yup.string().required("Obbligatorio"),
        otherwise: yup.string(),
      }),
      distretto_notaio: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .when("forma_id", {
          is: () => parseInt(socIdTipo.value) >= 2,
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required("Obbligatorio")
            .nullable(),
        }),
      n_ordine: yup.string().when("forma_id", {
        is: () => parseInt(socIdTipo.value) >= 2,
        then: yup.string().required("Obbligatorio"),
        otherwise: yup.string(),
      }),
      n_registro_pers_giuridiche: yup.string().when("forma_id", {
        is: () => parseInt(socIdTipo.value) >= 2,
        then: yup.string().required("Obbligatorio"),
        otherwise: yup.string(),
      }),
      prefettura: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .when("forma_id", {
          is: () => attoRegione.value == null && parseInt(socIdTipo.value) >= 2,
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required("Obbligatorio")
            .nullable(),
        }),
      regione: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .when("forma_id", {
          is: () =>
            attoPrefettura.value == null && parseInt(socIdTipo.value) >= 2,
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required("Obbligatorio")
            .nullable(),
        }),
      /*  fd10: yup
        .string()
        .required("Obbligatorio")
        .nullable()
        .typeError("Obbligatorio"), */
      conferma_atto: yup
        .boolean()
        .oneOf([true], "Obbligatorio")
        .required("Obbligatorio")
        .nullable(),
    });

    //form
    const onSubmit = async (values) => {
      values = {
        ...values,
        id: props.societa.id,
        id_societa: props.societa.id,
      };
      if (socIdTipo.value === 1) {
        values.cognome_notaio = null;
        values.distretto_notaio = null;
        values.n_ordine = null;
        values.n_registro_pers_giuridiche = null;
        values.prefettura = null;
        values.regione = null;
        values.n_registro_imprese = null;
        values.camera_commercio = null;
        if (values.forma_id === 3) {
          values.data_atto = null;
          values.ufficio_registro = null;
          values.estremi_registrazione = null;
        }
      }
      if (socIdTipo.value === 2) {
        values.n_registro_imprese = null;
        values.camera_commercio = null;
      }
      const attoEdited = await editAtto(values);
      if (!attoEdited) {
        alertFailed(
          "Attenzione! Si è verificato un errore. Riprovare più tardi."
        );
        return;
      }
      alertSuccess("Atto modificato");
      getAtto();
    };
    const onInvalidSubmit = () => {
      alertFailed("Compila i campi obbligatori");
    };

    const router = useRouter();

    onMounted(() => {
      if (router.currentRoute._value.name.includes("atto-societa"))
        setCurrentPageBreadcrumbs("Atto Societa", [
          "Lista Societa",
          "Dettaglio Societa",
        ]);
    });
    const customPosition = () => {
      return { top: 1000, left: 1000 };
    };

    return {
      customPosition,
      atto,
      regioni,
      onSubmit,
      onInvalidSubmit,
      format,
      loaded,
      status,
      prefettura,
      distretti,
      tipo,
      useFormatDateDMY,
      fromUTCtoISODate,
      schema,
      attoRegione,
      attoPrefettura,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
});
</script>
<style scoped>
div.dp__menu.dp__theme_light {
  top: 1rem !important;
}
</style>
